@keyframes fadeIn {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}

html {
  background-color: #111;
  color: #fff;
  margin: 0;
  padding: 0;
}

* {
  font-family: 'Merriweather', serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

img {
  height: 45%;
  width: 45%;
}

.App {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
}

.section {
  border: 1px solid '#fff';
  border-radius: 10%;
}

.header {
  animation-name: fadeIn;
  animation-duration: 1.5s;
}

.header-foldable {
  animation-name: fadeIn;
  animation-duration: 1.5s;
}

.main-content {
  animation-name: fadeIn;
  opacity: 0%;
  animation-duration: 1.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

/* small screens, phones */
@media only screen and (max-width: 600px) {
  .header {
    display: flex;
    height: 20%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .header-foldable {
    display: none;
  }

  .main-content {
    display: flex;
    height: 80%;
    width: 100%;
  }

  .sections {
    height: 100%;
    width: 40%;
  }

  .section {
    display: flex;
    height: 20%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .section-title {
    display: none;
  }

  .section-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}



/* large screen phones, portrait tablets */
@media only screen and (min-width: 600px) {
  .header {
    display: flex;
    height: 20%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .header-foldable {
    display: none;
  }

  .main-content {
    display: flex;
    justify-content: center;
    height: 80%;
    width: 100%;
  }

  .sections {
    height: 100%;
    width: 15%;
  }

  .section {
    display: flex;
    height: 25%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section-title {
    padding: 0.5em;
  }

  .section-content {
    height: 100%;
    width: 100%;
  }
}



/* landscape phones / tablets */
@media only screen and (min-width: 768px) {
  .header {
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 2.5em;
    align-items: center;
    justify-content: center;
  }

  .header-foldable {
    display: none;
  }

  .main-content {
    display: flex;
    justify-content: center;
    font-size: 1.25em;
    height: 80%;
    width: 100%;
  }

  .sections {
    height: 100%;
    width: 20%;
  }

  .section {
    display: flex;
    height: 25%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section-title {
    padding: 0.5em;
  }

  .section-content {
    height: 100%;
    width: 100%;
  }
}



/* laptops / desktops */
@media only screen and (min-width: 992px) {
  .header {
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 3em;
    align-items: center;
    justify-content: center;
  }

  .header-foldable {
    display: none;
  }

  .main-content {
    display: flex;
    justify-content: center;
    font-size: 1.5em;
    height: 80%;
    width: 100%;
  }

  .sections {
    height: 100%;
    width: 23%;
  }

  .section {
    display: flex;
    height: 25%;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .section-title {
    padding: 0.5em;
  }

  .section-content {
    height: 100%;
    width: 100%;
  }
}



/* large screens */
@media only screen and (min-width: 1200px) {
  .header {
    display: flex;
    height: 20%;
    width: 100%;
    font-size: 4em;
    align-items: center;
    justify-content: center;
  }

  .header-foldable {
    display: none;
  }

  .main-content {
    display: flex;
    justify-content: center;
    font-size: 1.75em;
    height: 80%;
    width: 100%;
  }

  .sections {
    height: 100%;
    width: 16%;
  }

  .section {
    display: flex;
    height: 25%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section-title {
    padding: 0.5em;
  }

  .section-content {
    height: 100%;
    width: 100%;
  }
}



/* foldable screens, horizontal */
@media(horizontal-viewport-segments: 2) and (vertical-viewport-segments: 1){
  .main-content {
    display: flex;
    height: calc(env(viewport-segment-height 0 0) -
      calc(100vh - env(viewport-segment-height 0 0) / 1.25));
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .header {
    height: calc(100vh - env(viewport-segment-height 0 0) / 1.25);
    width: calc(100vw - env(viewport-segment-width 1 0) - 
      calc(env(viewport-segment-left 1 0) - env(viewport-segment-right 0 0)));
  }

  .header-foldable {
    position: absolute;
    display: flex;
    height: calc(100vh - env(viewport-segment-height 0 0) / 1.25);
    width: calc(100vw - env(viewport-segment-width 1 0) - 
      calc(env(viewport-segment-left 1 0) - env(viewport-segment-right 0 0)));
    left: env(viewport-segment-left 1 0);
    top: 0;
    justify-content: center;
    align-items: center;
    font-size: 3em;
  }

  .sections {
    position: absolute;
    display: flex;
    height: calc(env(viewport-segment-height 0 0) - 
      calc(100vh - env(viewport-segment-height 0 0) / 1.25));
    width: env(viewport-segment-width 0 0);
    left: env(viewport-segment-left 0 0);
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section {
    height: 22%;
    width: 32%;
  }

  .section-content {
    position: absolute;
    display: flex;
    height: calc(env(viewport-segment-height 0 0) - 
      calc(100vh - env(viewport-segment-height 0 0) / 1.25));
    width: env(viewport-segment-width 1 0);
    left: env(viewport-segment-left 1 0);
    justify-content: center;
    align-items: center;
    }
}



/* foldable screens, vertical */
@media(horizontal-viewport-segments: 1) and (vertical-viewport-segments: 2) {
  .main-content {
    display: flex;
    height: calc(100vh - env(viewport-segment-height 0 0) + env(viewport-segment-height 0 0) / 2.3);
    width: env(viewport-segment-width 0 0);
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .header {
    width: calc(100vw - env(viewport-segment-left 1 0));
    height: calc(100vh - env(viewport-segment-height 0 0) - env(viewport-segment-height 0 0) / 2);
    font-size: 4em;
  }

  .header-foldable {
    display: none;
  }

  .sections {
    display: flex;
    height: calc(100vh - env(viewport-segment-height 0 0));
    width: env(viewport-segment-width 0 0);
    flex-direction: row;
    justify-content: center;
    font-size: 2em;
  }

  .section {
    height: calc(env(viewport-segment-height 0 0) / 3.5);
    width: calc(env(viewport-segment-width 0 0) / 5);
  }

  .section-content {
    position: absolute;
    height: env(viewport-segment-height 0 1);
    width: calc(100vw - env(viewport-segment-left 0 0));
    top: env(viewport-segment-top 0 1);
    font-size: 1.2em;
    }
}