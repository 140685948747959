.projects-div {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
}

.about-me-div {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}

.about-me-header {
    display: flex;
    height: 15%;
    width: 90%;
    justify-content: center;
    align-items: start;
    font-size: 1.25em;
}

.about-me-body {
    display: flex;
    height: 85%;
    width: 90%;
    justify-content: center;
    align-items: start;
    font-size: 1.15;
    overflow-y: auto;
}

.music-div {
    display: flex;
    width: 99%;
    height: 99%;
    justify-content: center;
    align-items: center;
}

table {
    display: flex;
    width: 100%;
    height: 100%;
}

tbody {
    height: 100%;
    width: 99%;
}

tr {
    display: flex;
    height: 25%;
    width: 100%;
    font-size: 1.25em;
    margin-left: 1%;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: nowrap;
}

td {
    height: 100%;
    width: 100%;
}

a {
    display: flex;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
}

a, a:hover, a:visited, a:active {
    color: inherit;
}

.project-image {
    min-height: 90%;
    max-height: 100%;
    max-width: 5em;
}

.project-description {
    margin: 2%;
    color: gray;
    font-size: 0.5em;
}

iframe {
    height: 99%;
    width: 99%;
    box-sizing: border-box;
    border: none;
}

::-webkit-scrollbar {
    background-color: #111;
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: #111;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 40%;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 255, 255, 1);
}


@media only screen and (max-width: 399px) {
    .project-image {
        width: 5em;
    }
}



@media only screen and (min-width: 400px) {
    .project-image {
        width: 7em;
    }
}



@media only screen and (min-width: 400px) and (orientation: landscape) {
    .project-image {
        width: 4em;
    }
}



/* foldable screen, horizontal */
@media(horizontal-viewport-segments: 2) and (vertical-viewport-segments: 1) {
    .projects-div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .projects-table {
        height: 62%;
        width: 62%;
    }

    .project-image {
        height: 32%;
        width: 32%;
    }
}



/* foldable screen, vertical */
@media(horizontal-viewport-segments: 1) and (vertical-viewport-segments: 2) {
    .projects-table {
        display: flex;
        height: env(viewport-segment-height 0 1);
        width: calc(env(viewport-segment-width 1 0) / 2);
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}